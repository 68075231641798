import MicroModal from "micromodal";
import toArray from "@/scripts/helpers/dom/toArray";

const getAllIframes = el => toArray(el.querySelectorAll("iframe"));

const execCommand = (frame, data, url) => {
  if (frame.contentWindow === null) {
    return;
  }

  frame.contentWindow.postMessage(window.JSON.stringify(data), url);
};

const execYoutubeCommand = (frame, command) => {
  execCommand(
    frame,
    { event: "command", func: command },
    "https://www.youtube.com"
  );
};

const execVimeoCommand = (frame, command) => {
  execCommand(frame, { method: command }, "https://player.vimeo.com");
};

const modalHandler = (modal, { youtube, vimeo }) => {
  const iframes = getAllIframes(modal);

  iframes.forEach(frame => {
    execYoutubeCommand(frame, youtube);
    execVimeoCommand(frame, vimeo);
  });
};

const pauseVideo = modal =>
  modalHandler(modal, { youtube: "pauseVideo", vimeo: "pause" });

const playVideo = modal =>
  modalHandler(modal, { youtube: "playVideo", vimeo: "play" });

const preventOverscroll = event => {
  event.preventDefault();
};

const config = {
  openTrigger: "data-modal-trigger",
  closeTrigger: "data-modal-close",
  awaitCloseAnimation: true
};

const _onClose = modal => {
  pauseVideo(modal);
  document.documentElement.classList.remove("scroll-locked");
  document.body.removeEventListener("touchmove", preventOverscroll, false);
};

const _onShow = modal => {
  modal.scrollTop = 0;
  playVideo(modal);
  document.documentElement.classList.add("scroll-locked");
  document.body.addEventListener("touchmove", preventOverscroll, false);
};

export default {
  init: ({ onClose = () => {}, onShow = () => {}, ...options } = {}) => {
    MicroModal.init(
      Object.assign({}, config, options, {
        onShow(modal) {
          onShow(modal);
          _onShow(modal);
        },
        onClose(modal) {
          onClose(modal);
          _onClose(modal);
        }
      })
    );
  },
  show: id => MicroModal.show(id, config),
  close: id => MicroModal.close(id, config)
};
