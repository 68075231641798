import Swiper from "swiper";
import Plugin from "@/scripts/core/Plugin";
import init from "@/scripts/core/init";

class Slider extends Plugin {
  defaults() {
    return {
      pagination: false, // "bullets",
      navigation: true,
      swiper: {
        spaceBetween: 30
      },
      thumbs: {
        selector: "",
        options: {}
      }
    };
  }

  init() {}

  get swiperOptions() {
    const config = {
      ...this.options.swiper
    };

    if (this.options.pagination && this.options.pagination === "bullets") {
      config.pagination = {
        el: this.element.querySelector(".slider__pagination"),
        type: "bullets",
        clickable: true,
        renderBullet(index, className) {
          return `<button type="button" class="button slider__bullet ${className}">
            <span class="visuallyhidden">${index + 1}</span>
          </button>`;
        }
      };
    }

    if (this.options.navigation) {
      config.navigation = {
        nextEl: this.element.querySelector(".slider__button_next"),
        prevEl: this.element.querySelector(".slider__button_prev")
      };
    }

    if (this.options.thumbs.selector) {
      const thumbElement = document.querySelector(
        this.options.thumbs.selector + " .swiper-container"
      );

      const thumbsSlider = new Swiper(
        thumbElement,
        this.options.thumbs.options
      );

      config.thumbs = {
        swiper: thumbsSlider
      };
    }

    return config;
  }

  buildCache() {
    this.swiperContainer = this.element.querySelector(".swiper-container");
    this.swiper = new Swiper(this.swiperContainer, this.swiperOptions);
  }

  getSwiper() {
    return this.swiper;
  }
}

export default init(Slider, "slider");
