import svg4everybody from "svg4everybody";

import * as serviceWorker from "@/scripts/helpers/serviceWorker";

import ready from "@/scripts/helpers/dom/ready";

import Offcanvas from "@/modules/offcanvas/offcanvas";
import ScrollTo from "@/modules/scroll-to/scroll-to";
import Slider from "@/modules/slider/slider";
import Modal from "@/modules/modal/modal";

Offcanvas();
ScrollTo("[data-scroll-to]");
Modal.init();
Slider(".how-it-works-screens__slider .slider", {
  pagination: "bullets",
  swiper: {
    loop: true,
    autoplay: {
      delay: 3000
    }
  }
});

ready(function() {
  svg4everybody();
});

// If you want your app to work offline and load faster, you can change
// `unregister()` to `register()` below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default {
  Offcanvas,
  File,
  ScrollTo,
  Slider,
  Modal
};
